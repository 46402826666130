import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// global component
import Pagnation from '@/components/pagnation.vue'
Vue.component('Pagnation', Pagnation)

// element ui
import ElementUI, { Notification } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// axios
import { post } from './common/request'
import './common/request'
Vue.prototype.$post = post;

// msg
Vue.prototype.$msg = function (title = '', type) {
  Notification({
    title,
    type: type == 'e' ? 'error' : 'success'
  })
}

// 全局混入
Vue.mixin({
  filters: {
    getTimeByFomat(duration = 0) {
      var m = parseInt(duration / 60)
      var s = duration % 60;
      m = m < 10 ? `0${m}` : m;
      s = s < 10 ? `0${s}` : s;
      return `${m}:${s}`;
    }
  },
  computed: {
    isLogin() { return !!this.$store.state.token },
    userInfo() { return this.$store.state.userInfo || {} }
  },
  methods: {
    /** 报告错题
     * @params {Object} {topic_id:currentTopic.id} 题目id
    */
    async escalationErrorTopic({ topic_id = '' }) {
      try {
        const res = await this.$post('648fb2d982145', { topic_id })
        this.$msg(res.msg || '错题上报成功')
      } catch (error) { this.$msg((error || {}).msg || '错题上报失败', 'e'); }
    },
    /**
     * 获取课程商品价格
     * @param {*} info 商品信息
     * @returns {type: 1 团购  2 售价  3  划线价  , price: 价格}
     */
    getClassPrice(info = {}) {
      /*
        rush_purchase   团购到期时间
        group_price     团购价
        sale_price      售价
        market_price    划线价【废弃】

        注：目前价格只会走到售价，0 免费
      */
      if (!+info.sale_price) return { type: 2, price: 0, }

      var result = {
        type: '',
        price: '',
      };
      if (info.rush_purchase && new Date(info.rush_purchase).getTime() > new Date().getTime()) {
        result.type = 1;
        result.price = +info.group_price;
      } else {
        result.type = 2;
        result.price = +info.sale_price || 0;
      }
      return result
    },
    /** 
     * 根据类型获取课程指定价格
     * @param Object info 课程信息
     * @param Number type 订单类型 1=普通订单 2=拼团订单
     */
    getCoursePriceByType(info = {}, type = 1) {
      if (type == 1) return +info.sale_price || 0;
      return +info.group_price || 0;
    }
  }
})

// 全局事件对象
Vue.prototype.eventBus = new Vue();

// 环境变量
Vue.prototype.isdev = process.env.NODE_ENV == "development";

// IM初始化
import { iMInit } from './common/liveModule/im'
Vue.prototype.$Tim = iMInit();

// 加入直播模块
import { liveInitSdk } from '@/common/liveModule/live.js';
liveInitSdk();

// vue 示例
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
