// 直播相关

/** 初始化直播SDK */
export function liveInitSdk() {

    // SDK1
    var dom1 = document.createElement('link');
    dom1.setAttribute('href', 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.6.0/tcplayer.min.css');
    dom1.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].append(dom1);

    // SDK2
    var dom2 = document.createElement('script');
    dom2.setAttribute('src', 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.6.0/libs/TXLivePlayer-1.2.3.min.js');
    document.body.append(dom2);

    // SDK3
    var dom3 = document.createElement('script');
    dom3.setAttribute('src', 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.6.0/libs/flv.min.1.6.3.js');
    document.body.append(dom3);

    // SDK4
    var dom4 = document.createElement('script');
    dom4.setAttribute('src', 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.6.0/libs/dash.all.min.4.4.1.js');
    document.body.append(dom4);

    // SDK5
    var dom4 = document.createElement('script');
    dom4.setAttribute('src', 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.6.0/tcplayer.v4.6.0.min.js');
    document.body.append(dom4);

}


