<template>
  <div class="pagnation_box fc">
    <span style="margin-right:20px;" v-if="totalPage">共{{ totalPage }}页</span>
    <el-pagination background layout="prev, pager, next" :current-page="currentPage"
                   :page-size="pageSize" :total="total" @current-change="pagChange" />
  </div>
</template>

<script>
export default {
  props: {
    /** 总条数 */
    total: Number,
    /** 每页多少条 */
    pageSize: {
      type: Number,
      default: 10
    },
    /** 当前第几页 */
    currentPage: {
      type: Number,
      default: 1
    },
    /** 共计多少页 */
    totalPage: Number
  },
  methods: {
    pagChange(e) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: e }
      })
      this.$emit('pageChange', e)
    }
  },
}
</script>

<style lang='less' scoped>
.pagnation_box {
 margin-top: 20px;
 display: flex;
 align-items: center;
 justify-content: flex-end;

 /deep/ .active {
  background-color: #d73532 !important;
 }
}
</style>