/**
 * IM 模块
 */
const SDKAppID = 1400786743;

// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
// v2.24.0起，SDK 支持使用本地审核插件
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';

/** 初始化SDK */
export function iMInit() {
    // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
    let options = { SDKAppID };
    // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
    let tim = TIM.create(options); // SDK 实例通常用 tim 表示

    // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
    // tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
    // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
    if (process.env.NODE_ENV == "development") tim.setLogLevel(0);
    else tim.setLogLevel(1);

    // 注册腾讯云即时通信 IM 上传插件
    tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });

    // 注册腾讯云即时通信 IM 本地审核插件
    tim.registerPlugin({ 'tim-profanity-filter-plugin': TIMProfanityFilterPlugin });

    // 返回聊天对象
    return tim;
}

